import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,2,3,10,6,9,11];

export const dictionary = {
		"/(app)/(with-seo)": [~12,[2,3]],
		"/(app)/badges": [~21,[2,7]],
		"/(app)/blog": [22,[2]],
		"/(app)/blog/[slug]": [~23,[2]],
		"/(app)/(with-seo)/casino": [~14,[2,3]],
		"/(auth)/check": [~39,[10]],
		"/(auth)/confirm": [~40,[10]],
		"/(app)/(with-seo)/faq": [~15,[2,3,4]],
		"/(app)/(with-seo)/faq/[slug]": [~16,[2,3,4]],
		"/(app)/(with-seo)/favorites": [~17,[2,3]],
		"/(auth)/forgot": [41,[10]],
		"/(app)/(with-seo)/game/[slug]": [18,[2,3,5]],
		"/(app)/halloffame": [24,[2]],
		"/(auth)/login": [42,[10]],
		"/logout": [~46],
		"/(app)/loyalty": [~25,[2]],
		"/(app)/luckiest": [26,[2]],
		"/(app)/(with-seo)/pvp": [19,[2,3]],
		"/(app)/(with-seo)/recent": [20,[2,3,6]],
		"/(auth)/reset-password": [~43,[10]],
		"/(app)/rewards": [~27,[2]],
		"/(app)/rps": [28,[2,8]],
		"/(app)/rr": [~29,[2]],
		"/(app)/settings": [30,[2,9]],
		"/(app)/settings/ignored": [31,[2,9]],
		"/(app)/settings/preference": [32,[2,9]],
		"/(app)/settings/security": [33,[2,9]],
		"/(app)/settings/sessions": [34,[2,9]],
		"/(app)/settings/verification": [35,[2,9]],
		"/signup": [47,[11]],
		"/(auth)/social": [44,[10]],
		"/(app)/terms": [~36,[2]],
		"/(app)/tournaments": [~37,[2]],
		"/(app)/tournaments/[slug]": [~38,[2]],
		"/(auth)/twofa": [45,[10]],
		"/(app)/(with-seo)/(games)/[slug=games]": [13,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';